gapi.load('auth2', function () {
  new_session_path = $('#new_session_path').val();
  google_oauth2_path = $('#google_oauth2_path').val();
  auth2 = gapi.auth2.init({
      client_id: "650474058389-penbd3idlrnfcg1s5rhhreft6mofghv9.apps.googleusercontent.com",
      cookie_policy: 'single_host_origin',
      scope: 'email profile',
      response_type: 'code'
  }).then(function (authResult) {
      var signedIn = authResult.isSignedIn.get();
      if (signedIn) {
        signedInCallback();
      } else {
        window.location = google_oauth2_path;
      }

      function signedInCallback() {
        var currentUser = authResult.currentUser.get();
        var authResponse = currentUser.getAuthResponse();
        var basicProfile = currentUser.getBasicProfile();

        $.ajax({type: 'POST', url: '/users/auth/google_oauth2/callback', data: authResponse,
          success: function(response, status, xhr) {},
          failure: function(response, status, xhr) {}
        });
      }
  });
});
